@import './common/colors';

.tooltip.in {
    opacity: 0.9;
}

* {
    font-family: Chonburi;
}

h1{
    font-family: Chonburi;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 58px;
    color: #000000;
}

h2{
    font-family: Chonburi;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}

h3{
    font-family: Chonburi;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    color: #000000;
}

h6{
    font-family: Sarabun;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;

    color: #000000;
}

body{
    font-family: Sarabun;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}

#age-slider {
    width: 100%;

    .slider-selection {
        background: #313A3A;
    }

    .slider-handle {
        background: #313A3A;
    }
}

#wage-slider {
    width: 100%;

    .slider-selection {
        background: #F4D25A;
    }

    .slider-handle {
        background: #F4D25A;
    }
}

.tooltip-inner {
    font-family: Sarabun;
}

.search-sidebar {
    background-color: #313A3A;
    
    color: white;

    min-height: 100vh;
    
    #age-slider {
        width: 100%;
    
        .slider-selection {
            background: #F4D25A;
        }
    
        .slider-handle {
            background: #F4D25A;
        }
    }
}

.description-popup {
    background: white;
    padding: 1em;
    font-family: Sarabun;
}

.sarabun {
    font-family: Sarabun;
}