.search-cat-icon {
    background-color: gray;
    width: 100px;
    height: 100px;
    border-radius: 50%;


    svg {
        width: 50px;
        height: 50px;

        margin: auto;
    }
}

.search-cat-icon.active {
    background-color: #F4D25A;
}